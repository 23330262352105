
export const user_info = (function () {
    let r = localStorage.getItem('user_info');
    if (!r) return {};
    return JSON.parse(r);
})();

export const shopping_cart = {
    content: []
};

export const KEYS = {
    CODE: localStorage.getItem('code'),
    TOKEN: localStorage.getItem('token')
};

export const global = {};


export default { global, user_info, shopping_cart, KEYS };