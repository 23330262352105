import './App.css';
import "react-datepicker/dist/react-datepicker.css";
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { BASE_ROUTE, LINE_ENTRY, TOKEN_FAILED_CODE, SESSION_EXPIRED_CODE, PAGE_SIZE, ENV, DEBUG, PRODUCT } from './config';
import { Link, Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { http_request } from './connection';
import { useEffect, useState } from 'react';
import { jumpToLineEntry, logout, bySort } from './lib';
import { XCircleFill } from 'react-bootstrap-icons';

const INIT = 0;
const READY = 1;
var APP_STATUS = INIT;
var HTTP_QUEUE = 0;

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [search_params, setSearchParams] = useSearchParams();
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [tip_visiblility, setTipVisiblility] = useState(false);
  const [cart, setCart] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [search_pannel_show, showSearchPannel] = useState(false);
  const [search, setSearch] = useState('');
  const [order_result, setOrderResult] = useState({});
  const [candidate_list, setCandidateList] = useState([]);
  const [search_history_list, setSearchHistoryList] = useState(loadHistory());
  const [categories, setCategories] = useState({
    name: '所有商品',
    children: {}
  });

  const [form_cache, setFormCache] = useState(null);
  

  useEffect(()=>{
  }, [categories]);
  useEffect(() => {
    initApp().then(()=>{
      if(ENV === PRODUCT) setToken('start');
      loadCart();
      loadCategories();
    });
  }, [location]);
  useEffect(()=>{
    if(search_pannel_show) document.getElementById('search').focus();
  }, [search_pannel_show]);
    
  function initApp(){
    if(APP_STATUS === INIT){
      APP_STATUS = READY;
      return readKeys();
    }
    return new Promise(resolve=>{ resolve(); });
  }
  function setCSRF(code){
    let params = { code };
    return new Promise(resolve=>{
      http_request('/sanctum/csrf-cookie', 'GET', params, true).then(res=>{
        console.log(res);
        resolve();
      });
    });
  }
  function readKeys(){//處理 token 相關
    setTipVisiblility(false);
    var code = (new URLSearchParams(window.location.search)).get('code');
    return new Promise(resolve=>{
      if(code){//如果本地沒有 token, 檢查網址參數有沒有 code
        setCSRF(code).then(()=>{
          readToken(code).then(()=>{//如果有, 拿去換 token
            resolve();
          });
        });
      }else{
        resolve();
      }
    });
  }
  function readToken(code){//拿 code 去嘗試換 token
    let params = { code };
    return new Promise(resolve=>{
      http_request('/customer/token', 'POST', params).then(res=>{
        if(!res.success && res.msg == TOKEN_FAILED_CODE){//兌換失敗, 顯示提示, 用戶讀完提示之後手動按一下, 跳轉去 LINE 登入
          setTipVisiblility(true);
        }else{
          if(ENV === DEBUG){
            localStorage.setItem('token', res.data.access_token);
            setToken(res.data.access_token);
          }
          navigate(BASE_ROUTE);
        }
        resolve();
      });
    });
  }
  function loadCart(){
    const params = {};
    http_request('/shopping-cart/list', 'POST', params).then(res=>{
      if(!res.success && res.msg == TOKEN_FAILED_CODE){
        jumpToLineEntry();
      }else{
        if(res.success){
          let c = res.data.shoppingCarts;
          setCart(c);
        }
      }
    });
  }
  function loadCategories(){
      const params = {};
      http_request('/product-category/list', 'POST', params).then(res=>{
          if(res.msg == SESSION_EXPIRED_CODE){
              logout();
              return;
          }else{
              (()=>{
                  var root = {
                    name: '所有商品',
                    children:{}
                  };
                  (res.data.product_categories || []).forEach(c=>{
                      if(c.product_category_id === null){
                          c.children = {};
                          root.children[c.id] = c;
                      }else{
                          walk(root, c);
                      }
                  });
                  function walk(node, child){ 
                      if(node.id == child.product_category_id){
                          child.children = {};
                          node.children[child.id] = child;
                          return;
                      }
                      if(Object.keys(node.children).length){
                          for(let p in node.children){
                              walk(node.children[p], child);
                          }
                      }
                  }
                  setCategories(root);
              })();
          }
      });
  }
  function loadCandidates(key){//當搜尋關鍵字變動的時候刷新候選詞, request 發出去的時候領號碼牌, 回來的時候檢查自己是不是最末號, 如果不是就直接 return 掉
    if(key === ''){//沒有關鍵字的時候不抓候選詞, 並且把號碼牌序號歸零
      HTTP_QUEUE = 0;
      setCandidateList([]);
      return;
    }

    HTTP_QUEUE++;
    let my_number = HTTP_QUEUE;
    var params = {
        page: 1,
        rows: PAGE_SIZE,
        search: key
    };
    http_request('/product/list', 'POST', params).then(res=>{
        if(HTTP_QUEUE !== my_number) return;
        if(res.msg == SESSION_EXPIRED_CODE){
            logout();
            return;
        }else{
            let candidates = (res.data.products || []).slice(0, 10).map(c=>c.name);
            setCandidateList(candidates);
        }
    });
  }
  function popSearchPannel(){
    showSearchPannel(true);
  }
  function searchProducts(){
    let q1 = search === undefined;
    let q2 = search === null;
    let q3 = (search + '').trim() == '';
    if(q1 || q2 || q3) return;
    
    showSearchPannel(false);
    setHistory(search);
    navigate({
      pathname: `${BASE_ROUTE}/`,
      search: `?search=${search}`
    });
  }
  function loadHistory(){
    let list = localStorage.getItem('search-history');
    if(list) return JSON.parse(list);
    return [];
  }
  
  function setHistory(new_str){
    let list = loadHistory();
    let pre_index = list.indexOf(new_str);
    if(pre_index === -1){//如果是新詞, 加進去
      list.unshift(new_str);
      list = list.slice(0, 10);
    }else{//如果是查詢過的舊詞, 順位往前提
      list.splice(pre_index, 1);
      list.unshift(new_str);
    }
    localStorage.setItem('search-history', JSON.stringify(list));
    setSearchHistoryList(list);
  }
  function clearHistory(){
    localStorage.setItem('search-history', '[]');
    setSearchHistoryList([]);
  }
  return (
    <div className="App bg-light">
      {
        tip_visiblility ? <FullScreenTip /> : ''
      }
      <header>
        <div className='d-none' onClick={()=>{ jumpToLineEntry(); }}>jumpToLineEntry</div>
        <Navbar bg="white" expand="lg" expanded={expanded} >
          <Container>
            <span className='flex-grow-1 d-flex align-items-center'>
              <Navbar.Toggle  
                aria-controls="basic-navbar-nav" 
                onClick={()=>{ setExpanded(!expanded); }} 
                children={expanded ? <img src={`${process.env.PUBLIC_URL}/assets/icon-actionbar-close-normal.svg`} /> : <img src={`${process.env.PUBLIC_URL}/assets/icon-actionbar-list-normal.svg`} />}
              />
              <Link to={`${BASE_ROUTE}/`}>
                <Navbar.Brand href={`${BASE_ROUTE}`}>
                  <img src={`${process.env.PUBLIC_URL}/assets/image-logo.svg`} style={{ height: '22px' }} />
                </Navbar.Brand>
              </Link>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto navbar-menu">
                  {Object.keys(categories.children).length ? Object.values(categories.children).sort(bySort).map((sub_nav, i)=>{
                    if(Object.keys(sub_nav.children).length){
                      return <NavDropdown className='basic-nav-item' key={i} title={<DropdownTitle name={sub_nav.name} />}>
                        {
                          Object.values(sub_nav.children).sort(bySort).map((child, c)=><Link 
                            className='text-secondary d-block'
                            to={`${BASE_ROUTE}/gallery/${child.id}`}
                            key={child.id}
                          >
                            <NavDropdown.Item  
                              as="li"
                              eventKey={child.id}
                              onClick={()=>{ setExpanded(false); }}
                            >
                                {child.name}
                            </NavDropdown.Item>
                          </Link>)
                        }
                      </NavDropdown>
                    }else{
                      return <Link className='d-flex align-items-center' key={sub_nav.id} to={`${BASE_ROUTE}/gallery/${sub_nav.id}`} onClick={()=>{ setExpanded(!expanded); }}>
                        <span  className='basic-nav-item nav-link'>{sub_nav.name}</span>
                      </Link>;
                    }
                  }) : ''}
                </Nav>
              </Navbar.Collapse>
            </span>
            {
              expanded ? '' : <span className='nav-tool-bar'>
                <span className='nav-tool-icon' onClick={()=>{ popSearchPannel(); }}>
                    <img src={`${process.env.PUBLIC_URL}/assets/icon-actionbar-search-normal.svg`} />
                </span>
                <NavToolIcon to={`${BASE_ROUTE}/account/bill_settings`} icon_image={`${process.env.PUBLIC_URL}/assets/icon-actionbar-user-normal.svg`} />
                <NavToolIcon to={`${BASE_ROUTE}/cart`} count={cart.length} icon_image={`${process.env.PUBLIC_URL}/assets/icon-actionbar-purchase-normal.svg`} />
              </span>
            }
          </Container>
        </Navbar>
        <div id="search-pannel" className={`${search_pannel_show ? '' : 'd-none'}`}>
          <div id="search-bar" className='d-flex align-items-center bg-white'>
            <div className='py-8 ps-16 pe-12' onClick={()=>{ showSearchPannel(false); }}>
              <img src={`${process.env.PUBLIC_URL}/assets/icon-actionbar-back-normal.svg`} />
            </div>
            <span className='d-flex flex-grow-1'>
              <input 
                id="search"
                className='flex-grow-1 py-8 px-12' 
                placeholder='搜尋'
                value={search}
                onChange={e=>{
                  let s = e.target.value;
                  console.log(s);
                  loadCandidates(s);
                  setSearch(s);
                }}
              />
              <span 
                id="remove-button"
                className={`px-12 ${search.length ? '' : 'd-none'}`}
                onClick={()=>{
                  loadCandidates('');
                  setSearch('');
                }}
              >
                  <XCircleFill size={14} fill="#b1b1b1"/>
              </span>
              <span 
                className='search-button pe-16'
                onClick={()=>{
                  searchProducts();
                }}
              >
                <span className='search-icon-container px-12 py-8'>
                  <img src={`${process.env.PUBLIC_URL}/assets/icon-button-search-normal.svg`} />
                </span>
              </span>
            </span>
          </div>
          {
            candidate_list.length ? 
            <ul className='search-candidate-list m-0 px-0 bg-white'>
              {
                candidate_list.map((row, i)=>{
                  if(search.length === 0) return '';
                  
                  let finds = [], match;
                  var regex = new RegExp(search, 'g');
                  while ((match = regex.exec(row)) != null) {
                    finds.push(match.index);
                  }
                  let segments = [''];
                  let pos = 0;
                  let start = 0;
                  while(start <= row.length){
                    if(row[start] === undefined) break;
                    if(finds.includes(start)){
                      pos++;
                      segments[pos] = search;
                      start += search.length;
                      pos++;
                      segments[pos] = '';
                    }else{
                      segments[pos] += row[start];
                      start++;
                    }
                  }

                  let content = segments.map((seg, s)=>{
                    return (seg === search) ? <strong key={s}>{seg}</strong> : <span key={s}>{seg}</span>
                  });

                  return <li className='candidate-item py-6 px-16' 
                    key={i}
                    onClick={()=>{
                      showSearchPannel(false);
                      setHistory(row);
                      navigate({
                        pathname: `${BASE_ROUTE}/`,
                        search: `?search=${row}`
                      });
                    }}
                  >
                    {
                      content
                    }
                  </li>;
                })
              }
            </ul> : <div className='d-flex flex-column bg-white'>
              <div className='mx-16 d-flex align-items-center justify-content-between underline'>
                <span className='py-16' style={{ fontWeight: 'bold', color: '#404040' }}>近期搜尋紀錄</span>
                <span 
                  className={`py-16 ps-20 ${search_history_list.length ? '' : 'd-none'}`} 
                  style={{ fontSize: '12px', color: '#404040' }}
                  onClick={()=>{ 
                    clearHistory();
                  }}
                >
                  清除全部紀錄
                </span>
              </div>
              <ul className='py-6 px-0 mb-0 bg-white'>
                {
                  (search_history_list || []).map((row, i)=><li 
                  key={i} 
                  className="search-history-item"
                  onClick={()=>{
                    showSearchPannel(false);
                    setHistory(row);
                    navigate({
                      pathname: `${BASE_ROUTE}/`,
                      search: `?search=${row}`
                    });
                  }}
                  >
                    {row}
                  </li>)
                }
              </ul>
            </div>
          }
        </div>
      </header>
        <Outlet context={{ 
          loadCart,
          shopping_cart: cart, 
          product_categories: categories,
          order_result,
          setOrderResult,
          form_cache,
          setFormCache
        }} />
    </div>
  );
}

function FullScreenTip(){
  const style_container = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
    background: '#ffffff',
    width: '100%',
    height: '100%'
  };
  const style_text = {
    color: 'red'
  };
  return <div className='d-flex flex-column justify-content-center align-items-center' style={style_container}>
    <div className='m-5' style={style_text}>Token 失效</div>
    <div><a href={LINE_ENTRY}><Button>使用 LINE 登入</Button></a></div>
  </div>
}

function NavToolIcon(props){
  return <Link to={props.to || ''} className='nav-tool-icon'>
    <img src={props.icon_image} />
    <span className={'badge ' + (props.count ? '' : 'opacity-0')}><span>{props.count}</span></span>
  </Link>;
}
function DropdownTitle(props){
  let { name } = props;
  return <span className='d-flex align-items-center justify-content-between basic-dropdown-item'>
    { name }
  </span>;
}

export default App;
