import './style.css';
import React, { Fragment, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { BASE_ROUTE, EXTEND, ORDER_STATUS_LIST, PAGE_SIZE, PAYMENT_LIST, REPLACE, SESSION_EXPIRED_CODE } from "../../config";
import { http_request } from "../../connection";
import { Toast } from "react-bootstrap";
import { createTimeString, logout, numberToCash } from "../../lib";
import LoadingTip from '../../components/loading_tip';
import ReactDatePicker from 'react-datepicker';
import { Dash } from 'react-bootstrap-icons';
import { useRef } from 'react';

var current_page = 1;
var data_size = null;

function OrderList(props){
    const a_month_ago = (()=>{
        let sd = new Date();
        sd = sd.setMonth(sd.getMonth() - 1);
        return sd;
    })();
    const location = useLocation();
    const [start_date, setStartDate] = useState(a_month_ago);
    const [end_date, setEndDate] = useState(new Date());
    const [temp_start_date, setTempStartDate] = useState(a_month_ago);
    const [temp_end_date, setTempEndDate] = useState(new Date());
    const [payment, setPayment] = useState('');
    const [orders, setOrders] = useState([]);
    const [toast_visibility, setToastVisibility] = useState(false);
    const [error_text, setErrorText] = useState('');
    const [is_loading, setLoadingProgress] = useState(false);
    const [range, setRange] = useState('1');
    const [date_selector_show, showDateSelecotr] = useState(false);
    const [date_picker_start_show, showDatePickerStart] = useState(false);
    const [date_picker_end_show, showDatePickerEnd] = useState(false);

    const toggleToast = ()=>{ setToastVisibility(!toast_visibility); };

    useEffect(()=>{
        current_page = 1;
        queryByFilter(REPLACE);
    }, [location, payment, range, start_date, end_date]);


    function selectOrderStatus(v){
        setPayment(v);
    }
    function selectRange(v){
        let sd = new Date();
        let ed = new Date();
        sd = sd.setMonth(sd.getMonth() - v);
        setRange(v);
        setStartDate(sd);
        setEndDate(ed);
    }
    function popDateSelector(){
        setTempStartDate(start_date);
        setTempEndDate(end_date);
        showDateSelecotr(true);
    }
    function closeDateSelector(){
        setStartDate(temp_start_date);
        setEndDate(temp_end_date);
        showDateSelecotr(false);
    }
    function queryByFilter(method = EXTEND){//透過參數來 filter
        var params = {
            start_datetime: createTimeString('y/m/d', start_date) + ' 00:00:00',
            end_datetime: createTimeString('y/m/d', end_date) + ' 23:59:59',
            payment: payment,
            search: '',
            rows: PAGE_SIZE,
            page: current_page
        };
        query(params, method);
    }
    function query(params, method = EXTEND){
        if(is_loading) return;
        if(params.status == '') delete params.status;
        setLoadingProgress(true);
        http_request('/order/list', 'POST', params).then(res=>{
            setLoadingProgress(false);
            if(res.msg == SESSION_EXPIRED_CODE){
                logout();
                return;
            }else{
                if(res.success){
                    console.log(res);
                    switch(method){
                        case EXTEND:
                            setOrders(orders.concat(res.data.orders || []));
                            break;
                        case REPLACE:
                            setOrders(res.data.orders || []);
                            break;
                    }
                    data_size = res.data.total;
                }else{
                    setOrders([]);
                    setErrorText(Object.values(res.data));
                    setToastVisibility(true);
                }
            }
        });
    }
    function loadMore(e){
        let { scrollHeight, clientHeight, scrollTop } = e.target;
        if((scrollHeight - clientHeight) === scrollTop){
            if(is_loading) return;
            if(data_size > 0 && (current_page * PAGE_SIZE) > data_size) return;
            current_page += 1;
            queryByFilter(EXTEND);
        }
    }
    return (<div id="page-order-list" className={"p-16 flex-column flex-grow-1 overflow-auto " + (props.show ? 'd-flex' : ( props.show === undefined ? 'd-flex' : 'd-none'))} onScroll={e=>{ loadMore(e); }}>
        <LoadingTip is_loading={is_loading} />
        <Modal
            show={date_selector_show}
            centered
            onHide={()=>{
                showDateSelecotr(false);
            }}
        >
            <div id="page-order-list-date-selector" className='p-16 pb-20'>
                <div className='pb-12 underline text-center fs-16 fw-bold'>
                    選擇期間
                </div>
                <div className='d-flex align-items-center justify-content-center' style={{ paddingTop: '25px', paddingBottom: '28px' }}>
                    <span className='date-picker-container start-date'>
                        <ReactDatePicker
                            dateFormat="y/M/d"
                            selected={start_date}
                            open={date_picker_start_show}
                            onFocus={()=>{ showDatePickerStart(true); }}
                            onClose={()=>{ showDatePickerStart(false); }}
                            onClickOutside={()=>{ showDatePickerStart(false); }}
                            onChange={d=>{
                                setStartDate(d);
                                showDatePickerStart(false);
                            }}
                        />
                        <img onClick={()=>{ showDatePickerStart(true); }} src={`${process.env.PUBLIC_URL}/assets/icon-button-calendar-normal.svg`} />
                    </span>
                    <span className='dash-container'><Dash /></span>
                    <span className='date-picker-container end-date'>
                        <ReactDatePicker
                            dateFormat="y/M/d"
                            selected={end_date}
                            open={date_picker_end_show}
                            onFocus={()=>{ showDatePickerEnd(true); }}
                            onClose={()=>{ showDatePickerEnd(false); }}
                            onClickOutside={()=>{ showDatePickerEnd(false); }}
                            onChange={d=>{
                                setEndDate(d);
                                showDatePickerEnd(false);
                            }}
                        />
                        <img onClick={()=>{ showDatePickerEnd(true); }} src={`${process.env.PUBLIC_URL}/assets/icon-button-calendar-normal.svg`} />
                    </span>
                </div>
                <div className='d-flex justify-content-center'>
                    <button type='button' className='mono-button button light flex-grow-1' style={{ marginRight: '5px' }} onClick={()=>{ closeDateSelector(); }}>取消</button>
                    <button type='button' className='mono-button button dark flex-grow-1' style={{ marginLeft: '5px' }} onClick={()=>{
                        setRange('-');
                        showDateSelecotr(false);
                    }}>確定</button>
                </div>
            </div>
        </Modal>
        <div className="d-flex flex-column flex-grow-1" style={{ marginTop: '8px' }}>
            <div id="query" className='block px-16 py-20'>
                <Form.Group className="mb-2">
                    <Form.Select onChange={e=>{ selectOrderStatus(e.currentTarget.value); }} value={payment}>
                        <option value="">全部</option>
                        {
                            Object.values(PAYMENT_LIST).map((p, i)=><option key={i} value={p}>{p}</option>)
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-2">
                    <Form.Select
                        onChange={e=>{
                            let v = e.currentTarget.value;
                            if(v === ''){
                                popDateSelector();
                            }else{
                                selectRange(v);
                            }
                        }
                    } value={range}>
                        <option value="1">過去一個月</option>
                        <option value="3">過去三個月</option>
                        <option value="">選擇期間</option>
                        <option value="-" hidden>{createTimeString('y/m/d', start_date)} ~ {createTimeString('y/m/d', end_date)}</option>
                    </Form.Select>
                </Form.Group>
            </div>
                <div id="result" className=" d-flex flex-column flex-grow-1">
                    {
                        orders.length ? <div>
                            {
                                orders.map((order, i) => <div key={i} className="order block mb-16 pt-8">
                                    <div className='underline card-head'>
                                        <span className='order-id'>
                                            <img className='me-8' src={`${process.env.PUBLIC_URL}/assets/icon-display-receipt.svg`} />
                                            <span className='text fw-bold'>{order.id}</span>
                                        </span>
                                        <Link to={`${BASE_ROUTE}/order/${order.id}`}><button type='button' className='button mono-button dark'>查閱</button></Link>
                                    </div>
                                    <table className='w-100'>
                                        <tbody>
                                            <tr>
                                                <td>訂單日期：</td>
                                                <td className='text-end'>{createTimeString('y/m/d', new Date(order.datetime))}</td>
                                            </tr>
                                            <tr>
                                                <td>合計金額：</td>
                                                <td className='text-end'>NT{numberToCash(order.total)}</td>
                                            </tr>
                                            <tr>
                                                <td>付款方式：</td>
                                                <td className='text-end'>{order.payment}</td>
                                            </tr>
                                            <tr>
                                                <td>訂單狀態：</td>
                                                <td className='text-end'>{(ORDER_STATUS_LIST[order.status] || {}).name}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>)
                            }
                        </div> : 
                        <div className="empty-tip d-flex flex-column flex-grow-1 bg-light align-items-center justify-content-start">
                            <img src={`${process.env.PUBLIC_URL}/assets/image-empty-receipt.svg`} />
                            <span className="text mt-16">目前尚無訂單唷~</span>
                        </div>
                    }
                </div>
        </div>
        <Toast 
            show={toast_visibility}
            onClose={toggleToast}
            position="bottom-end"
            delay={5000}
            autohide
            className="position-fixed"
            style={{zIndex: 99999, bottom: 10, left: 10}}
        >
            <Toast.Header>
                <span style={{ flex: 1 }}>查詢失敗</span>
            </Toast.Header>
            <Toast.Body>{error_text}</Toast.Body>
        </Toast>
    </div>);
}
export default OrderList;