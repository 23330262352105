import { API_ADDR, DEBUG, ENV, ROOT_ADDR, SESSION_EXPIRED_CODE } from './config';
import { user_info } from './global';
import { logout } from './lib';

function formatParam(param, prefix=''){
    if(Array.isArray(param)){
        return formatArray(param, prefix).flat();
    }else if(typeof param === 'object'){
        return formatObject(param, prefix).flat();
    }else{
        return param;
    }
}
function formatArray(arr, prefix){
    let output = [];
    for(let i = 0 ; i < arr.length ; i++){
        output.push(dispatch(prefix, i, arr));
    }
    return output;
}
function formatObject(obj, prefix){
    let output = [];
    for(let p in obj){
        output.push(dispatch(prefix, p, obj));
    }
    return output;
}
function dispatch(prefix, index, obj){
    let new_prefix = prefix === '' ? encodeURIComponent(index) : prefix + '['+ encodeURIComponent(index) +']';
    if(typeof obj[index] === 'object'){
        return formatParam(obj[index], new_prefix);
    }else{
        let v = encodeURIComponent(obj[index]);
        return new_prefix + '=' + v;
    }
}

export function http_request(path, method = 'GET', params = {}, root_path = false) {
    let url = root_path ? `${ROOT_ADDR}${path}` : `${API_ADDR}${path}`;

    switch (method) {
        case 'POST':
            var form_data = formatParam(params);
            form_data = form_data.join("&");
            break;
        case 'GET':
            if(Object.keys(params).length){
                url += '?';
                for(let p in params){
                    url += `${p}=${params[p]}`;
                }
                url = encodeURI(url);
            }
            break;
    }
    let header_config = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Accept': 'application/json'
    };
    if(ENV === DEBUG) header_config.Authorization = 'Bearer ' + localStorage.getItem('token');
    return fetch(url, {
        method,
        body: form_data,
        credentials: 'include',
        headers: new Headers(header_config)
    }).then(response => {
        if(response.status == 200){
            return response.json();
        }else if((response.status + '').substring(0,2) !== '20'){
            throw new Error(response.status);
        }
    }).catch(e=>{
        console.log('catch');
        console.error(e)
        logout();
    });
}

export function check_response(response, callback){
    if(!callback) callback = ()=>{};
    if(response.message == SESSION_EXPIRED_CODE){
        callback();
        return false;
    }else{
        return true;
    }
}

export default { http_request, check_response };