import './style.css';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useOutletContext } from "react-router-dom";
import { BASE_ROUTE, SESSION_EXPIRED_CODE } from "../../config";
import { http_request } from "../../connection";
import { calculateDiscount, createTimeObject, logout, numberToCash } from "../../lib";
import { autoFill } from "../gallery/tools";
import { DashCircle, PlusCircle } from 'react-bootstrap-icons';
import { Breadcrumb, BreadcrumbItem, Button, Carousel, CarouselItem } from "react-bootstrap";
import { NumberButton } from '../../components/number_button';
import LoadingTip from '../../components/loading_tip';
import Toast from '../../components/toast';

function Product(props){
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = params;
    const { shopping_cart, product_categories } = useOutletContext();
    const [product, setProduct] = useState({
        discount_expiration: createTimeObject()
    });
    const [count, setCount] = useState(1);
    const [cart_tip, setCartTip] = useState('');
    const [toast_in, showToast] = useState(false);
    const [cate_path, setCatePath] = useState([]);
    const [is_loading, setLoadingProgress] = useState(false);



    useEffect(()=>{
        loadProductInfo();
    }, [location]);
    useEffect(()=>{
        findCategoryPath();
    }, [product, product_categories]);


    function loadProductInfo(){
        if(is_loading) return;
        let params = { product_id: id };
        setLoadingProgress(true);
        http_request(`/product/get`, 'POST', params).then(res=>{
            setLoadingProgress(false);
            if(res.msg == SESSION_EXPIRED_CODE){
                logout();
                return;
            }else{
                let pi = calculateDiscount(res.data.product ? res.data.product : {});
                setProduct(autoFill(pi));
            }
        });
    }
    function modifyCount(c){
        if(count + c < 1){
            setCount(1);
        }else{
            setCount(count + c);
        }
    }
    function addToCart(success_callback){
        if(is_loading) return;
        const i = shopping_cart.findIndex(p=>{
            return p.product.id == id;
        });
        const is_new = (i == -1);
        var quantity, method, params;
        if(is_new){
            quantity = count;
            method = 'create';
            params = {
                product_id: id,
                quantity
            };
        }else{
            quantity = shopping_cart[i].quantity += count;
            method = 'edit';
            params = {
                product_id: id,
                quantity,
                shopping_cart_id: shopping_cart[i].id
            };
        }
        setLoadingProgress(true);
        http_request(`/shopping-cart/${method}`, 'POST', params).then(res=>{
            setLoadingProgress(false);
            if(res.msg == SESSION_EXPIRED_CODE){
                logout();
                return;
            }else{
                if(res.success){
                    setCartTip(
                        <React.Fragment>
                            <img src={`${process.env.PUBLIC_URL}/assets/icon-toast-success.svg`} />
                            <span style={{ paddingLeft: '6px' }}>已新增至購物車</span>
                        </React.Fragment>
                    );
                    showToast(true);
                    success_callback();
                }else{
                    setCartTip(Object.values(res.data));
                    showToast(true);
                }
            }
        });
    }
    function findCategoryPath(){
        let path = [];
        const product_category_id = product.product_category_id;
        if(!product_category_id){
            console.log('invalid id: ' + product_category_id);
            return;
        }
        function find(node){
            path.push(node);
            if(node.id === product.product_category_id){
                return true;
            }else{
                if(Object.keys(node.children).length){
                    for(let p in node.children){
                        let res = find(node.children[p]);
                        if(res) return true;
                    }
                }
                path = path.slice(0, path.length - 1);
                return false;
            }
        }
        find(product_categories);
        setCatePath(path);
    }
    return (<div className="page-content" id="page-product">
        <LoadingTip is_loading={is_loading} />
        <Toast 
            toast_in={toast_in}
            showToast={showToast}
            children={cart_tip}
        />
        {
            product.images && product.images.length ? 
            <div className='slider-container mx-auto'>
                <Carousel fade slide={false} interval={null}>
                    {
                        product.images.map((img_src, i)=><CarouselItem key={i}>
                            <img src={img_src} className="d-block w-100" />
                        </CarouselItem>)
                    }
                </Carousel>
            </div> : ''
        }
        <div className='px-16 bg-white box-shadow'>
            <h1 className="pt-12 fw-bold fs-16">{product.name}</h1>
            <div className='brand'>SHEIN</div>
            <div className='d-flex align-items-center pb-16'>
                <span className='discount-price'>{numberToCash(product.discount_price)}</span>
                <span className='price ms-8' style={{ paddingTop: '4px' }}>{numberToCash(product.price)}</span>
                <div className={`discount-info ms-8 ${product.discount_rate === null ? 'd-none' : ''}`}>
                    <span className="rate">{product.discount_rate}折</span>
                    <span className="date">優惠至{product.discount_expiration.y}/{product.discount_expiration.m}/{product.discount_expiration.d}</span>
                </div>
            </div>
        </div>
        <div className='quantity-control my-8 px-16 py-14 bg-white d-flex align-items-center justify-content-between box-shadow'>
            <span className='fw-bold'>數量</span>
            <div className='d-flex align-items-cener justify-content-center'>
                <NumberButton icon={DashCircle} onClick={()=>{ modifyCount(-1)}} disabled={product.stock == 0 || count == 1} />
                {
                    product.stock ? <span className='count'>{count}</span> : <span className='empty-stock'>無庫存</span>
                }
                
                <NumberButton icon={PlusCircle} onClick={()=>{ modifyCount(1)}} disabled={product.stock == 0} />
            </div>
        </div>
        <div className='px-16 py-12 bg-white mb-8 box-shadow'>
            <div className='underline pb-12 fw-bold'>商品詳情</div>
            <div className='pt-12' dangerouslySetInnerHTML={{ __html: product.detail_description }}>
            </div>
        </div>
        <div className='p-16 bg-white box-shadow d-flex align-items-center justify-content-center'>
            <button type='button' className='button mono-button light me-8 flex-grow-1' onClick={()=>{ 
                addToCart();
            }}>加入購物車</button>
            <button type='button' className='button mono-button dark ms-8 flex-grow-1' onClick={()=>{ 
                let success = ()=>{ navigate(`${BASE_ROUTE}/cart`); }
                addToCart(success);
            }}>立即購買</button>
        </div>
    </div>);
}

export default Product;