export const DEBUG = 0;
export const PRODUCT = 1;
export const ENV = PRODUCT;
export const BASE_ROUTE = '';
export const DOMAIN = ENV === DEBUG ? '48de-60-250-228-214.ap.ngrok.io' : 'ec.cooli.game.tw';
export const PROTOCOL = 'https';
export const API_ADDR = `${PROTOCOL}://${DOMAIN}/frontend`;
export const ROOT_ADDR = `${PROTOCOL}://${DOMAIN}`;
export const SESSION_EXPIRED_CODE = "Unauthenticated.";
export const TOKEN_FAILED_CODE = '無效的code';
export const API_STATE = 'web';
export const LINE_ENTRY = `${PROTOCOL}://${DOMAIN}/frontend/customer/login?state=${API_STATE}`;
export const PAGE_SIZE = window.screen.height ? Math.ceil(window.screen.height / 100) : 1;
export const ORDER_STATUS_LIST = {
    pending_payment: {
        key: 'pending_payment',
        name: '已收到訂單，等待付款'
    },
    processing: {
        key: 'processing',
        name: '已收到訂單，訂單處理中'
    },
    on_hold: {
        key: 'on_hold',
        name: '保留'
    },
    completed: {
        key: 'completed',
        name: '完成'
    },
    cancelled: {
        key: 'cancelled',
        name: '取消'
    },
    refounded: {
        key: 'refounded',
        name: '已退費'
    },
    failed: {
        key: 'failed',
        name: '失敗'
    }
};
export const PAYMENT_LIST = [
    '貨到付款',
    '信用卡',
    '網路轉帳',
    'LINE PAY'
];

export const EXTEND = 0;
export const REPLACE = 1;


export const COUNTRY_DEFAULT_VALUE = '台灣';
export const DISTRICT_DEFAULT_VALUE = '';
export const CITY_DEFAULT_VALUE = '';

export const TAB_ADDRESS_SETTINGS = 0;
export const TAB_MY_ORDERS = 1;

export const TYPE_BILL = 'bill';
export const TYPE_SHIPPING = 'shipping';
export const BILL_FORM_ITEMS = [{
    label: '姓氏',
    name: 'last_name',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: '名稱',
    name: 'name',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: '公司',
    name: 'company',
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: '連絡電話',
    name: 'contact_number',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: '地址1',
    name: 'address_1',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: '地址2',
    name: 'address_2',
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: '城市',
    name: 'city',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
},{
    label: '郵遞區號',
    name: 'postal_code',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
}/*,{
    label: '國家',
    name: 'country',
    required: true,
    scope: [TYPE_BILL, TYPE_SHIPPING]
}*/,{
    label: '備註',
    name: 'remark',
    scope: [TYPE_BILL]
},{
    label: '門市名稱',
    name: 'shipping_purchaser_store',
    scope: [TYPE_SHIPPING]
},{
    label: '門市電話',
    name: 'shipping_purchaser_phone',
    scope: [TYPE_SHIPPING]
},{
    label: '門市代碼',
    name: 'shipping_purchaser_code',
    scope: [TYPE_SHIPPING]
}];

export default {
    DEBUG,
    PRODUCT,
    ENV,
    API_ADDR, 
    ROOT_ADDR,
    BASE_ROUTE,
    CITY_DEFAULT_VALUE,
    COUNTRY_DEFAULT_VALUE, 
    DISTRICT_DEFAULT_VALUE,
    EXTEND,
    REPLACE,
    BILL_FORM_ITEMS,
    TAB_ADDRESS_SETTINGS,
    TAB_MY_ORDERS,
    TYPE_BILL, 
    TYPE_SHIPPING, 
    SESSION_EXPIRED_CODE, 
    TOKEN_FAILED_CODE, 
    LINE_ENTRY, 
    ORDER_STATUS_LIST,
    PAYMENT_LIST
};