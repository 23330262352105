import './style.css';
import { Link, useOutletContext } from "react-router-dom";
import CartProgressChart from "../../components/cart_progress_chart/cart_progress_chart";
import { BASE_ROUTE, ORDER_STATUS_LIST } from "../../config";
import { createTimeString, numberToCash } from '../../lib';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from '../../components/toast';
import React, { useState } from 'react';

export function Result(){
    const context = useOutletContext();
    const { result, order, code, msg } = context.order_result || {};

    return (<div id="page-result" className="page-content d-flex flex-column p-16">
        <CartProgressChart step={3} />
        {
            result ? <SuccessPannel order={order} /> : <ErrorPanel msg={msg} />
        }
    </div>);
}

function SuccessPannel(props){
    let { order } = props;
    return <div>
        <div className='res-content mt-16 col-12 col-sm-6 offset-sm-3 py-20 mb-16'>
            <div className='d-flex align-items-center justify-content-center pb-16'>
                <img src={`${process.env.PUBLIC_URL}/assets/cart_success.svg`} />
            </div>
            <div className='tip underline px-16 pb-20'>
                <span className='tip-text'>已成功提交訂單</span>
                <img className='tip-icon' src={`${process.env.PUBLIC_URL}/assets/icon-display-success.svg`} />
            </div>
            <div className='data-table p-16'>
                <div className='data-row'>
                    <label>訂單編號：</label>
                    <span>{order.id}</span>
                </div>
                <div className='data-row'>
                    <label>訂單日期：</label>
                    <span>{createTimeString('y/m/d', new Date(order.datetime))}</span>
                </div>
                <div className='data-row'>
                    <label>合計金額：</label>
                    <span>{numberToCash(order.total || 0)}</span>
                </div>
                <div className='data-row'>
                    <label>付款方式：</label>
                    <span>{order.payment}</span>
                </div>
                <div className='data-row'>
                    <label>訂單狀態：</label>
                    <span>{(ORDER_STATUS_LIST[order.status] || {}).name || '--'}</span>
                </div>
            </div>
            <div className='button-group px-16'>
                <Link className='d-flex' to={`${BASE_ROUTE}/order/${order.id}`}><button type='button' className='dark flex-grow-1'>查看訂單</button></Link>
                <Link className='d-flex' to={`${BASE_ROUTE}/`}><button type='button' className='mono-button light flex-grow-1'>繼續逛逛</button></Link>
            </div>
        </div>
        {
            order.payment === '網路轉帳' ? <PaymentInfo order={order} /> : ''
        }
    </div>;
}

function ErrorPanel(props){
    let { msg } = props;
    return <div className='res-content mt-16 col-12 col-sm-6 offset-sm-3 py-20'>
        <div className='d-flex align-items-center justify-content-center pb-16'>
            <img src={`${process.env.PUBLIC_URL}/assets/image-error-purchase.svg`} />
        </div>
        <div className='tip px-16'>
            <span className='tip-text'>提交訂單失敗</span>
            <img className='tip-icon' src={`${process.env.PUBLIC_URL}/assets/icon-display-error.svg`} />
        </div>
        <div className='pt-16 text-center mb-12'>{msg || ''}</div>
        <div className='d-flex flex-column px-16 pt-12'>
            <Link to={`${BASE_ROUTE}/cart`} className="d-flex mb-12">
                <button type='button' className='mono-button button dark flex-grow-1'>返回購物車</button>
            </Link>
            <Link to={`${BASE_ROUTE}/`} className="d-flex">
                <button type='button' className='mono-button button light flex-grow-1'>繼續逛逛</button>
            </Link>
        </div>
    </div>;
}

function PaymentInfo(props){
    let { order } = props;
    const [toast_in, showToast] = useState(false);
    return <React.Fragment>
        <Toast children={<span>
            <img src={`${process.env.PUBLIC_URL}/assets/icon-toast-success.svg`} />
            <span>已複製</span>
        </span>} toast_in={toast_in} showToast={showToast}/>
        <div className='block pt-12 mb-16 payment-info  col-12 col-sm-6 offset-sm-3'>
            <div className='underline fw-bold pb-12'>付款資訊</div>
            <div className='underline fw-bold py-12 bank-info'>
                <img src={`${process.env.PUBLIC_URL}/assets/chinatrust-logo.svg`} />
                <span>中國信託銀行</span>
            </div>
            <div className='underline py-12'>
                <div className='label'>銀行代碼</div>
                <div className='strong'>822</div>
            </div>
            <div className='underline py-12 account'>
                <div className='label'>帳戶號碼</div>
                <div className='strong d-flex align-items-center justify-content-between'>
                    <span>
                        <span className='account-code'>4383</span>
                        <span className='account-code'>8000</span>
                        <span className='account-code'>6642</span>
                        <span className='account-code'>9522</span>
                    </span>
                    <CopyToClipboard className='copy-button' text={'4383800066429522'} onCopy={()=>{ showToast(true); }}>
                        <img src={`${process.env.PUBLIC_URL}/assets/icon-button-copy-normal.svg`} />
                    </CopyToClipboard>
                </div>
            </div>
            <div className='underline py-12'>
                <div className='label'>付款金額</div>
                <div className='strong'>
                    NT{numberToCash((order.order_details || []).reduce(function(p, c){
                        return p + (c.discount_price * c.quantity);
                    }, 0) + order.delivery_fee)}
                </div>
            </div>
            <div className='underline py-12 expiration'>
                <div className='label'>付款期限</div>
                <div className='strong'>在2022年8月8日 23:14到期</div>
                <div className='warning'>剩餘2天23小時59分鐘</div>
                <div className='warning'>*期限到期仍尚未付款則自動取消訂單</div>
            </div>
            <div className='pt-12'>
                <div className='label'>注意事項</div>
                <ol className='ps-12 mb-0 tips'>
                    <li>請使用網路銀行或實體ATM將金額轉入以上帳戶。</li>
                    <li>轉帳完成後30分鐘可至訂單頁面查詢付款狀態</li>
                    <li>請保留帳單收據直至帳款入帳，若轉帳後48小時仍未入帳，請再與我們聯繫查詢。</li>
                </ol>
            </div>
        </div>
    </React.Fragment>;
}
export default Result;