import './style.css';

export default function CartProgressChart(props){
    return <div id="cart-progress-chart" className='steps-container'>
        <span className='progress-bar'></span>
        <span className='step-group'>
            <span className={ 'step' + (props.step == 1 ? ' active' : '')}>
                <div className='circle'><span>1</span></div>
                <label>購物車</label>
            </span>
            <span className={ 'step' + (props.step == 2 ? ' active' : '')}>
                <div className='circle'><span>2</span></div>
                <label>填寫資料</label>
            </span>
            <span className={ 'step' + (props.step == 3 ? ' active' : '')}>
                <div className='circle'><span>3</span></div>
                <label>訂單確認</label>
            </span>
        </span>
    </div>;
}