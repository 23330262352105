import './style.css';
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import taiwan_districts from '../../assets/taiwan_districts.json';
import Form from 'react-bootstrap/Form';
import { 
    BASE_ROUTE, 
    CITY_DEFAULT_VALUE,
    COUNTRY_DEFAULT_VALUE,
    DISTRICT_DEFAULT_VALUE,
    SESSION_EXPIRED_CODE,
    TYPE_BILL,
    TYPE_SHIPPING
 } from "../../config";
import { http_request } from "../../connection";
import { logout } from "../../lib";
import LoadingTip from "../../components/loading_tip";
import Toast from '../../components/toast';

const config_template = {
    city: CITY_DEFAULT_VALUE,
    country: COUNTRY_DEFAULT_VALUE,
    address_1: DISTRICT_DEFAULT_VALUE,//district 分到 address_1
    address_2: ''
};

function BillSettings(props){
    const location = useLocation();
    const [validated, setValidated] = useState(false);
    const [config_bill, setBillConfig] = useState(Object.assign({}, config_template));
    const [config_shipping, setShippingConfig] = useState(Object.assign({}, config_template));
    const [is_loading, setLoadingProgress] = useState(false);
    const [bill_info_check, checkBillInfo] = useState(false);
    const [shipping_info_check, checkShippingInfo] = useState(false);
    const [copy_check, checkCopy] = useState(false);
    const [toast_in, showToast] = useState(false);
    const [toast_content, setToastContent] = useState('');

    useEffect(()=>{
        loadInfo();
    }, [location]);

    async function loadInfo(){
        let list_bill = await loadBillInfo(TYPE_BILL);
        let list_shipping = await loadBillInfo(TYPE_SHIPPING);
        setBillConfig(list_bill.length ? list_bill[0] : Object.assign({}, config_template));
        setShippingConfig(list_shipping.length ? list_shipping[0] : Object.assign({}, config_template));
    }
    async function loadBillInfo(type){
        let params = {
            type
        };
        return new Promise(resolve=>{
            http_request('/customer-bill-info/list', 'POST', params).then(res=>{
                if(res.msg == SESSION_EXPIRED_CODE){
                    logout();
                    return;
                }else{
                    if(res.success){
                        resolve(res.data.customerBillInfos);
                    }else{
                        resolve([]);
                    }
                }
            });
        });
    }
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if(form.checkValidity()){
            setLoadingProgress(true);
            let send = (config, bill_type)=>{
                let path, params = Object.assign({}, config);
                params.country = params.country || COUNTRY_DEFAULT_VALUE;
                params.last_name = '-';
                params.postal_code = (()=>{
                    let city = taiwan_districts.find(city=>city.name === config.city);
                    if(!city) return null;
                    let district = city.districts.find(district=>district.name === config.address_1);
                    if(!district) return null;
                    return district.zip;
                })();
                if(config.id){//編輯現有 config
                    params.customer_bill_info_id = params.id;
                    path = 'edit';
                }else{//用戶未存任何 config，開新
                    params.type = bill_type;
                    path = 'create';
                }
                for(let p in params){
                    if(params[p] === null) params[p] = '';
                }
                console.log(params);
                return http_request(`/customer-bill-info/${path}`, 'POST', params);
            };
            Promise.all([
                send(config_bill, TYPE_BILL),
                send(config_shipping, TYPE_SHIPPING),
            ]).then(()=>{
                setLoadingProgress(false);
                loadInfo();
                setToastContent(<Fragment>
                    <img src={`${process.env.PUBLIC_URL}/assets/icon-toast-success.svg`} />
                    <span style={{ paddingLeft: '6px' }}>已儲存</span>
                    </Fragment>
               );
               showToast(true);
            });
        }
        
    };
    return (<div id="page-bill-settings" className={"px-16 pb-16 flex-column flex-grow-1 overflow-auto " + (props.show ? 'd-flex' : ( props.show === undefined ? 'd-flex' : 'd-none')) } style={{ paddingTop: '24px' }}>
        <LoadingTip is_loading={is_loading} />
        <Toast children={toast_content} toast_in={toast_in} showToast={showToast}/>
        <div className="greetings">
            <img src={`${process.env.PUBLIC_URL}/assets/icon-display-profile.svg`} />
            <span className="text">你好, 親愛的貴賓</span>
            <span className="customer_name">{config_bill.name || config_shipping.name || '遊客'}</span>
        </div>
        <div className='d-flex flex-column flex-grow-1 mt-16'>
            <Form noValidate validated={validated} className="d-flex flex-column flex-grow-1" onSubmit={handleSubmit}>
                <div className="block mb-16">
                    <div className='title underline mb-12 pb-12 d-flex justify-content-between'>
                        <span>貨物寄送資訊 <img src={`${process.env.PUBLIC_URL}/assets/icon-display-edit.svg`} /> </span>
                    </div>
                    <div className='underline pb-16'>
                        <Form.Group>
                            <Form.Label className='user-select-none' htmlFor={`shipping_info_name`}>收件人姓名</Form.Label>
                            <Form.Control 
                                id={`shipping_info_name`}
                                className="no-arrow"
                                type='text' 
                                placeholder="請填入收件人真實姓名，以確保順利收件"
                                name="name"
                                required={true}
                                onChange={e=>{
                                    let new_data = Object.assign({}, config_shipping);
                                    new_data.name = e.currentTarget.value;
                                    setShippingConfig(new_data);
                                }}
                                value={config_shipping.name || ''}
                            />
                            <Form.Control.Feedback className='text-end' type="invalid">
                                姓名不可為空白
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className='user-select-none my-12' htmlFor={`shipping_info_contact_number`}>收件人電話</Form.Label>
                            <Form.Control 
                                id={`shipping_info_contact_number`}
                                className="no-arrow"
                                type='text'
                                placeholder="09XXXXXXXX"
                                name="contact_number"
                                required={true}
                                onChange={e=>{
                                    let new_data = Object.assign({}, config_shipping);
                                    new_data.contact_number = e.currentTarget.value;
                                    setShippingConfig(new_data);
                                }}
                                value={config_shipping.contact_number || ''}
                            />
                            <Form.Control.Feedback className='text-end' type="invalid">
                                電話不可為空白
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Label className="user-select-none my-12">送貨地點：台灣</Form.Label>
                            <div className='d-flex mb-12'>
                                <Form.Select 
                                    className='flex-grow-1 me-1'
                                    name="shipping_info_city"
                                    required
                                    value={config_shipping.city}
                                    onChange={e=>{
                                        let new_city = e.currentTarget.value;
                                        let new_data = Object.assign({}, config_shipping);
                                        new_data.city = new_city;
                                        new_data.address_1 = (new_city === CITY_DEFAULT_VALUE) ? DISTRICT_DEFAULT_VALUE : taiwan_districts.find(city=>city.name === new_city).districts[0].name;
                                        setShippingConfig(new_data);
                                    }}
                                    style={{
                                        marginBottom: '4px'
                                    }}
                                >
                                    <option value="">城市/縣</option>
                                    {
                                        taiwan_districts.map((city, i)=><option key={i} value={city.name}>{city.name}</option>)
                                    }
                                </Form.Select>
                                <Form.Select 
                                    className='flex-grow-1 ms-1'
                                    name="shipping_info_district"
                                    required
                                    value={config_shipping.address_1}
                                    onChange={e=>{
                                        let new_data = Object.assign({}, config_shipping);
                                        new_data.address_1 = e.currentTarget.value;
                                        setShippingConfig(new_data);
                                    }}
                                    style={{
                                        marginBottom: '4px'
                                    }}
                                >
                                    <option value="">地區</option>
                                    {
                                        (config_shipping.city === CITY_DEFAULT_VALUE) ? '' :
                                        (((taiwan_districts.find(city=>(city.name === config_shipping.city)) || { districts: [] }).districts || [])).map((district, i)=><option key={i} value={district.name}>{district.name}</option>)
                                    }
                                </Form.Select>
                            </div>
                            <Form.Control
                                id={`shipping_info_address_2`}
                                className="no-arrow"
                                type='text'
                                placeholder="請填入完整地址"
                                name="address_2"
                                required={true}
                                onChange={e=>{
                                    let new_data = Object.assign({}, config_shipping);
                                    new_data.address_2 = e.currentTarget.value;
                                    setShippingConfig(new_data);
                                }}
                                value={config_shipping.address_2 || ''}
                            />
                        </Form.Group>
                    </div>
                    <Form.Group className="mt-16 d-none">{/** 設為 d-none 暫時槓起來 */}
                            <Form.Check>
                                <Form.Check.Input
                                    id={`shipping_info_save_check`}
                                    type='checkbox'
                                    checked={shipping_info_check}
                                    value='shipping_info'
                                    onChange={e=>{ 
                                        let checked = shipping_info_check;
                                        checkShippingInfo(!checked);
                                    }}
                                />
                                <Form.Label htmlFor={`shipping_info_save_check`} className="user-select-none ms-1">設定為貨物寄送預設資訊</Form.Label>
                            </Form.Check>
                    </Form.Group>
                </div>
                    <div className="block mb-16">
                        <div className='title underline mb-12 pb-12 d-flex justify-content-between'>
                            <span>帳單寄送資訊 <img src={`${process.env.PUBLIC_URL}/assets/icon-display-edit.svg`} /> </span>
                            <Form.Group className="d-flex">
                                <Form.Check>
                                    <Form.Check.Input 
                                        id="copy_from_bill_info"
                                        type='checkbox'
                                        checked={copy_check}
                                        onChange={e=>{
                                            let accepted = !copy_check;
                                            let new_data = Object.assign({}, config_bill);
                                            ['name', 'contact_number', 'city', 'country', 'address_1', 'address_2'].forEach(row=>{
                                                new_data[row] = (accepted ? (config_shipping[row] || '') : '');
                                            });
                                            setBillConfig(new_data);
                                            checkCopy(!copy_check);
                                        }}
                                    />
                                </Form.Check>
                                <Form.Label htmlFor="copy_from_bill_info" className="user-select-none ms-1">同上</Form.Label>
                            </Form.Group>
                        </div>
                        <div className='underline pb-16'>
                            <Form.Group>
                                <Form.Label className='user-select-none' htmlFor={`bill_info_name`}>收件人姓名</Form.Label>
                                <Form.Control 
                                    id={`bill_info_name`}
                                    className="no-arrow"
                                    type='text' 
                                    placeholder="請填入收件人真實姓名，以確保順利收件"
                                    name="name"
                                    required={true}
                                    onChange={e=>{
                                        let new_data = Object.assign({}, config_bill);
                                        new_data.name = e.currentTarget.value;
                                        setBillConfig(new_data);
                                    }}
                                    value={config_bill.name || ''}
                                />
                                <Form.Control.Feedback className='text-end' type="invalid">
                                    姓名不可為空白
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='user-select-none my-12' htmlFor={`bill_info_contact_number`}>收件人電話</Form.Label>
                                <Form.Control 
                                    id={`bill_info_contact_number`}
                                    className="no-arrow"
                                    type='text'
                                    placeholder="09XXXXXXXX"
                                    name="contact_number"
                                    required={true}
                                    onChange={e=>{
                                        let new_data = Object.assign({}, config_bill);
                                        new_data.contact_number = e.currentTarget.value;
                                        setBillConfig(new_data);
                                    }}
                                    value={config_bill.contact_number || ''}
                                />
                                <Form.Control.Feedback className='text-end' type="invalid">
                                    電話不可為空白
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group>
                                <Form.Label className="user-select-none my-12">送貨地點：台灣</Form.Label>
                                <div className='d-flex mb-12'>
                                    <Form.Select 
                                        className='flex-grow-1 me-1'
                                        name="bill_info_city"
                                        required
                                        value={config_bill.city}
                                        onChange={e=>{
                                            let new_city = e.currentTarget.value;
                                            let new_data = Object.assign({}, config_bill);
                                            new_data.city = new_city;
                                            new_data.address_1 = (new_city === CITY_DEFAULT_VALUE) ? DISTRICT_DEFAULT_VALUE : taiwan_districts.find(city=>city.name === new_city).districts[0].name;
                                            setBillConfig(new_data);
                                        }}
                                        style={{
                                            marginBottom: '4px'
                                        }}
                                    >
                                        <option value="">城市/縣</option>
                                        {
                                            taiwan_districts.map((city, i)=><option key={i} value={city.name}>{city.name}</option>)
                                        }
                                    </Form.Select>
                                    <Form.Select 
                                        className='flex-grow-1 ms-1'
                                        name="bill_info_district"
                                        required
                                        value={config_bill.address_1}
                                        onChange={e=>{
                                            let new_data = Object.assign({}, config_bill);
                                            new_data.address_1 = e.currentTarget.value;
                                            setBillConfig(new_data);
                                        }}
                                        style={{
                                            marginBottom: '4px'
                                        }}
                                    >
                                        <option value="">地區</option>
                                        {
                                            (config_bill.city === CITY_DEFAULT_VALUE) ? '' :
                                            (((taiwan_districts.find(city=>(city.name === config_bill.city)) || { districts: [] }).districts || [])).map((district, i)=><option key={i} value={district.name}>{district.name}</option>)
                                        }
                                    </Form.Select>
                                </div>
                                <Form.Control
                                    id={`bill_info_address_2`}
                                    className="no-arrow"
                                    type='text'
                                    placeholder="請填入完整地址"
                                    name="address_2"
                                    required={true}
                                    onChange={e=>{
                                        let new_data = Object.assign({}, config_bill);
                                        new_data.address_2 = e.currentTarget.value;
                                        setBillConfig(new_data);
                                    }}
                                    value={config_bill.address_2 || ''}
                                />
                            </Form.Group>
                        </div>
                        <Form.Group className="mt-16 d-none">{/** 設為 d-none 暫時槓起來 */}
                                <Form.Check>
                                    <Form.Check.Input
                                        id={`bill_info_save_check`}
                                        type='checkbox'
                                        checked={bill_info_check}
                                        value='shipping_info'
                                        onChange={e=>{ 
                                            let checked = bill_info_check;
                                            checkBillInfo(!checked);
                                        }}
                                    />
                                    <Form.Label htmlFor={`bill_info_save_check`} className="user-select-none ms-1">設定為帳單寄送預設資訊</Form.Label>
                                </Form.Check>
                        </Form.Group>
                    </div>
                    <div className='button-group d-flex'>
                        <Link className='d-flex flex-grow-1' to={`${BASE_ROUTE}/`}><button type='button' className='mono-button button-cancel light flex-grow-1'><span style={{ color: '#404040' }}>取消</span></button></Link>
                        <button type='submit' className='mono-button button-save dark flex-grow-1'><span>儲存變更</span></button>
                    </div>
            </Form>
        </div>
    </div>);
}
export default BillSettings;