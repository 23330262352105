import './style.css';
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Collapse, Form, Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { 
    BASE_ROUTE,
    ORDER_STATUS_LIST, 
    PAYMENT_LIST, 
    SESSION_EXPIRED_CODE,
} from "../../config";
import { http_request } from "../../connection";
import { createTimeString, logout, numberToCash } from "../../lib";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Toast from '../../components/toast';



export function Order(){
    const url_params = useParams();
    const order_id = url_params.id;
    const location = useLocation();
    const [order, setOrder] = useState({});
    const [cancel_order_dialog_show, showCancelOrderDialog] = useState(false);
    const [result_dialog_show, showResultDialog] = useState(false);
    const [payment_dialog_show, showPaymentDialog] = useState(false);
    const [result_text, setResultText] = useState('');

    useEffect(()=>{
        loadOrder();
    }, [location]);

    function loadOrder(){
        var params = {
            order_id
        };
        return new Promise(resolve=>{
            http_request('/order/get', 'POST', params).then(res=>{
                if(res.msg == SESSION_EXPIRED_CODE){
                    logout();
                    return;
                }else{
                    if(res.success){
                        setOrder(res.data.order);
                        resolve();
                    }
                }
            });
        });
    }
    function popResultDialog(msg){
        setResultText(msg);
        showResultDialog(true);
    }
    return <div id="page-order" className="page-content d-flex flex-column flex-grow-1">
        <div className='col-12 col-sm-6 offset-sm-3 d-flex flex-column flex-grow-1 p-16'>
            <ResultDialog 
                dialog_show={result_dialog_show} 
                showDialog={showResultDialog}
                text={result_text}
            />
            <CancelOrderDialog 
                order_id={order.id}
                dialog_show={cancel_order_dialog_show} 
                showDialog={showCancelOrderDialog}
                popResultDialog={popResultDialog}
                loadOrder={loadOrder}
            />
            <PaymentChangeDialog 
                order_id={order.id}
                payment={order.payment} 
                dialog_show={payment_dialog_show} 
                showDialog={showPaymentDialog}
                popResultDialog={popResultDialog}
                loadOrder={loadOrder}
            />
            <OrderInfo order={order} />
            {
                order.payment === "網路轉帳" ? <PaymentInfo order={order} showDialog={showPaymentDialog} showResultDialog={showResultDialog} /> : ''
            }
            <ItemList order={order} />
            <Summary order={order} />
            <DeliveryInfo order={order} />
            <BillInfo order={order} />
            {
                (order.status === 'pending_payment') ? <>
                    <div className='d-flex p-1'>
                        <button type='button' className='button mono-button light flex-grow-1' onClick={()=>{ 
                            showPaymentDialog(true);
                        }}>變更付款方式</button>
                    </div>
                    <div className='d-flex p-1'>
                        <button type='button' className='button mono-button light flex-grow-1' onClick={()=>{ 
                            showCancelOrderDialog(true);
                        }}>取消訂單</button>
                    </div>
                </> : ''
            }
        </div>
    </div>;
}

function CancelOrderDialog(props){
    const navigate = useNavigate();
    const { dialog_show, showDialog, order_id, loadOrder, popResultDialog } = props;
    function cancelOrder(){
        let params = {
            order_id
        };
        return new Promise(resolve=>{
            http_request('/order/cancel', 'POST', params).then(res=>{
                if(res.msg == SESSION_EXPIRED_CODE){
                    logout();
                    resolve();
                    return;
                }else{
                    if(res.success){
                        navigate(`${BASE_ROUTE}/account/order_list`);
                    }else{
                        popResultDialog(res.msg, true);
                    }
                    resolve();
                }
            });
        });
    }
    return <Modal
            show={dialog_show}
            centered
            onHide={()=>{ showDialog(false); }}
        >
        <div id="page-order-list-date-selector" className='p-16 pb-20'>
            <div className='text-center fs-16 fw-bold' style={{ marginBottom: '36px', marginTop: '24px' }}>
                是否要取消此筆訂單？
            </div>
            <div className='d-flex justify-content-center'>
                <button type='button' className='mono-button button light flex-grow-1' style={{ marginRight: '5px' }} onClick={()=>{ showDialog(false); }}>我要保留</button>
                <button type='button' className='mono-button button dark flex-grow-1' style={{ marginLeft: '5px' }} onClick={()=>{
                    cancelOrder();
                    showDialog(false);
                }}>取消訂單</button>
            </div>
        </div>
    </Modal>
}
function ResultDialog(props){
    const {dialog_show, showDialog, text} = props;
    return <Modal
            show={dialog_show}
            centered
            onHide={()=>{ showDialog(false); }}
        >
        <div id="page-order-list-date-selector" className='p-16'>
            <div className='text-center fs-16 fw-bold' style={{ marginBottom: '36px', marginTop: '24px' }}>
                {text || '操作失敗'}
            </div>
            <div className='d-flex justify-content-center pb-8'>
                <button type='button' className='mono-button button dark flex-grow-1' style={{ maxWidth: '311px' }} onClick={()=>{
                    showDialog(false);
                }}>確定</button>
            </div>
        </div>
    </Modal>
}
function PaymentChangeDialog(props){
    const { dialog_show, showDialog, order_id, loadOrder, popResultDialog } = props;
    const [payment, setPayment] = useState(props.payment);
    const [toast_in, showToast] = useState(false);

    useEffect(()=>{
        setPayment(props.payment);
    }, [props.payment]);

    function updatePayment(){
        let params = {
            order_id,
            payment
        };
        return new Promise(resolve=>{
            http_request('/order/editPayment', 'POST', params).then(res=>{
                if(res.msg == SESSION_EXPIRED_CODE){
                    logout();
                    resolve();
                    return;
                }else{
                    if(res.success){
                        loadOrder();
                        showToast(true);
                    }else{
                        popResultDialog(res.msg, true);
                    }
                    resolve();
                }
            });
        });
    }
    return <Fragment>
        <Toast children={<span>
            <img src={`${process.env.PUBLIC_URL}/assets/icon-toast-success.svg`} />
            <span>已變更付款方式</span>
        </span>} toast_in={toast_in} showToast={showToast}/>
        <Modal
            show={dialog_show}
            centered
            onHide={()=>{ showDialog(false); }}
        >
            <div className='p-16 pb-20'>
                <div className='underline pb-12 text-center fs-16 fw-bold'>
                    變更付款方式
                </div>
                {
                    PAYMENT_LIST.map((payment_candidate, p)=><Form.Check key={p} className='underline py-12 d-flex align-items-center justify-content-between'>
                        <Form.Label htmlFor={`candidate_${p}`} className="user-select-none mb-0 flex-grow-1">{payment_candidate}</Form.Label>
                        <Form.Check.Input
                            className='me-8'
                            id={`candidate_${p}`}
                            type='radio'
                            name="payment"
                            value={payment_candidate}
                            checked={payment === payment_candidate}
                            onChange={e=>{ setPayment(e.currentTarget.value); }}
                        />
                    </Form.Check>)
                }
                <div className='d-flex justify-content-center' style={{ marginTop: '24px', marginBottom: '4px' }}>
                    <button type='button' className='mono-button button light flex-grow-1' style={{ marginRight: '5px' }} onClick={()=>{ showDialog(false); }}>取消</button>
                    <button type='button' className='mono-button button dark flex-grow-1' disabled={!payment} style={{ marginLeft: '5px' }} onClick={()=>{
                        updatePayment();
                        showDialog(false);
                    }}>變更</button>
                </div>
            </div>
        </Modal>
    </Fragment>
}
function OrderInfo(props){
    let { order } = props;
    return <div className='block px-16 pb-16 pt-0 mb-16'>
        <div className='underline py-12 mb-12 fw-bold'>訂單資訊</div>
        <div>
            <table className="w-100">
                <tbody>
                    <tr>
                        <td>訂單編號</td>
                        <td className='text-end'>{order.id}</td>
                    </tr>
                    <tr>
                        <td>訂單日期</td>
                        <td className='text-end'>{createTimeString('y/m/d', order.datetime ? ( new Date(order.datetime) ) : ( new Date() ))}</td>
                    </tr>
                    <tr>
                        <td>合計金額</td>
                        <td className='text-end'>NT{numberToCash(order.total)}</td>
                    </tr>
                    <tr>
                        <td>付款方式</td>
                        <td className='text-end'>{order.payment}</td>
                    </tr>
                    <tr>
                        <td>訂單狀態</td>
                        <td className='text-end'>{(ORDER_STATUS_LIST[order.status] || {}).name}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
}
function PaymentInfo(props){
    let { order } = props;
    const [toast_in, showToast] = useState(false);
    return <React.Fragment>
            <Toast children={<span>
                <img src={`${process.env.PUBLIC_URL}/assets/icon-toast-success.svg`} />
                <span>已複製</span>
            </span>} toast_in={toast_in} showToast={showToast}/>
            <div className='block pt-12 mb-16 payment-info'>
                <div className='underline fw-bold pb-12'>付款資訊</div>
                <div className='underline fw-bold py-12 bank-info'>
                    <img src={`${process.env.PUBLIC_URL}/assets/chinatrust-logo.svg`} />
                    <span>中國信託銀行</span>
                </div>
                <div className='underline py-12'>
                    <div className='label'>銀行代碼</div>
                    <div className='strong'>822</div>
                </div>
                <div className='underline py-12 account'>
                    <div className='label'>帳戶號碼</div>
                    <div className='strong d-flex align-items-center justify-content-between'>
                        <span>
                            <span className='account-code'>4383</span>
                            <span className='account-code'>8000</span>
                            <span className='account-code'>6642</span>
                            <span className='account-code'>9522</span>
                        </span>
                        <CopyToClipboard className='copy-button' text='4383800066429522' onCopy={()=>{ showToast(true); }}>
                            <img src={`${process.env.PUBLIC_URL}/assets/icon-button-copy-normal.svg`} />
                        </CopyToClipboard>
                    </div>
                </div>
                <div className='underline py-12'>
                    <div className='label'>付款金額</div>
                    <div className='strong'>
                        NT{numberToCash((order.order_details || []).reduce(function(p, c){
                            return p + (c.discount_price * c.quantity);
                        }, 0) + (order.delivery_fee || 0))}
                    </div>
                </div>
                <div className='underline py-12 expiration'>
                    <div className='label'>付款期限</div>
                    <div className='strong'>在2022年8月8日 23:14到期</div>
                    <div className='warning'>剩餘2天23小時59分鐘</div>
                    <div className='warning'>*期限到期仍尚未付款則自動取消訂單</div>
                </div>
                <div className='py-12'>
                    <div className='label'>注意事項</div>
                    <ol className='ps-12 mb-0 tips'>
                        <li>請使用網路銀行或實體ATM將金額轉入以上帳戶。</li>
                        <li>轉帳完成後30分鐘可至訂單頁面查詢付款狀態</li>
                        <li>請保留帳單收據直至帳款入帳，若轉帳後48小時仍未入帳，請再與我們聯繫查詢。</li>
                    </ol>
                </div>
            </div>
        </React.Fragment>;
}
function Summary(props){
    let { order } = props;
    return <div className='block pt-12 mb-16'>
        <div className='underline fw-bold pb-12'>金額資訊</div>
        <div className='py-12 underline'>
            <div className='d-flex align-items-center justify-content-between'>
                <span>小計：</span>
                <span>NT{numberToCash((order.order_details || []).reduce(function(p, c){
                    return p + (c.original_price * c.quantity);
                }, 0))}</span>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
                <span>折扣：</span>
                <span>-NT{numberToCash((order.order_details || []).reduce(function(p, c){
                    return p + (c.original_price * c.quantity);
                }, 0) - (order.total || 0))}</span>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
                <span>運費：</span>
                <span>NT{numberToCash(order.delivery_fee)}</span>
            </div>
        </div>
        <div className='d-flex align-items-center justify-content-between pt-12 fw-bold'>
            <span>合計：</span>
            <span>NT{numberToCash((order.order_details || []).reduce(function(p, c){
                    return p + (c.discount_price * c.quantity);
                }, 0) + (order.delivery_fee || 0))}</span>
        </div>
    </div>;
}
function DeliveryInfo(props){
    let { order } = props;
    return <div className='block pt-12 mb-16'>
        <div className='underline fw-bold pb-12'>貨物寄送資訊</div>
        <div className='mt-12'>
            <table className="w-100">
                <tbody>
                    <tr>
                        <td>收件人姓名：</td>
                        <td className='text-end'>{(order.order_shipping_info || {}).name}</td>
                    </tr>
                    <tr>
                        <td>收件人電話：</td>
                        <td className='text-end'>{(order.order_shipping_info || {}).contact_number}</td>
                    </tr>
                    <tr>
                        <td>送貨地點：</td>
                        <td className='text-end'>
                            {(order.order_shipping_info || {}).postal_code}
                            {(order.order_shipping_info || {}).address_1}
                            {(order.order_shipping_info || {}).address_2}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
}
function BillInfo(props){
    let { order } = props;
    return <div className='block pt-12 mb-16'>
        <div className='underline fw-bold pb-12'>帳單寄送資訊</div>
        <div className='mt-12'>
            <table className="w-100">
                <tbody>
                    <tr>
                        <td>收件人姓名：</td>
                        <td className='text-end'>{(order.order_bill_info || {}).name}</td>
                    </tr>
                    <tr>
                        <td>收件人電話：</td>
                        <td className='text-end'>{(order.order_bill_info || {}).contact_number}</td>
                    </tr>
                    <tr>
                        <td>帳單寄送地點：</td>
                        <td className='text-end'>
                            {(order.order_bill_info || {}).postal_code}
                            {(order.order_bill_info || {}).address_1}
                            {(order.order_bill_info || {}).address_2}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
}
function ItemList(props){
    let { order } = props;
    const [product_list_show, showProductList] = useState(true);
    return <div className='product-info block px-16 py-0 mb-16'>
        <div className='title py-12 d-flex align-items-center justify-content-between'>
            <span className='fw-bold'>商品資訊</span>
            <span className={`ps-20 ${product_list_show ? '' : 'closed'}`} onClick={()=>{
                showProductList(!product_list_show);
            }}>
                <img src={`${process.env.PUBLIC_URL}/assets/icon-button-arrow-up-normal.svg`} />
            </span>
        </div>
        <Collapse in={product_list_show}>
            <div>
                <div className='underline' style={{ marginBottom: '4px' }}></div>
                <div className='item-list'>
                    {
                        (order.order_details || []).map((item, i)=><div key={i} className="d-flex item py-8">
                            <div className='img-container'>
                                {
                                    item.product.images.length ? <img className='product-picture' src={item.product.images[0]} /> : ''
                                }
                            </div>
                            <div className='ps-12 d-flex flex-column flex-grow-1'>
                                <div className='name fw-bold'>{item.product.name}</div>
                                <div className='d-flex flex-column justify-content-end flex-grow-1'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span></span>
                                        {
                                            item.original_price != item.product.discount_price ? <span className='fs-12 text-muted text-decoration-line-through'>{numberToCash(item.original_price)}</span> : ''
                                        }
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <span className='fs-12'>
                                            <span>數量：</span>
                                            <span>{item.quantity}</span>
                                        </span>
                                        <span className='fw-bold'>{numberToCash(item.product.discount_price)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                    {/* <div className='d-flex mt-16 mb-20'>
                        <button type='button' className='flex-grow-1 mono-button button dark'>再次加入購物車</button>
                    </div> */}
                </div>
            </div>
        </Collapse>
    </div>;
}

export default Order;